import { Flex } from '@gobob/ui';
import { NavLinkProps } from 'react-router-dom';

import { StyledLogo } from './Logo.style';

type Props = {
  onPress?: () => void;
  to?: string;
};

type InheritAttrs = Omit<NavLinkProps, keyof Props | 'children'>;

type LogoProps = Props & InheritAttrs;

const Logo = ({ to = '/', onPress, ...props }: LogoProps) => (
  <Flex alignItems='center' gap='s'>
    <StyledLogo {...(props as any)} aria-label='navigate to home page' to={to} onClick={onPress} onKeyDown={onPress}>
      <svg fill='none' height='32' viewBox='0 0 131 32' width='131' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
          fill='#F25D00'
        />
        <path
          d='M9.98877 12.6654V17.5438C9.98877 17.7117 10.1249 17.8478 10.2928 17.8478H15.1712C15.3391 17.8478 15.4752 17.7117 15.4752 17.5438V12.6654C15.4752 12.4975 15.3391 12.3614 15.1712 12.3614H10.2928C10.1249 12.3614 9.98877 12.4975 9.98877 12.6654Z'
          fill='white'
        />
        <path
          d='M9.99023 19.5213V24.3997C9.99023 24.5676 10.1263 24.7037 10.2942 24.7037H15.1726C15.3405 24.7037 15.4766 24.5676 15.4766 24.3997V19.5213C15.4766 19.3534 15.3405 19.2173 15.1726 19.2173H10.2942C10.1263 19.2173 9.99023 19.3534 9.99023 19.5213Z'
          fill='white'
        />
        <path
          d='M16.8464 19.5218V24.4002C16.8464 24.5681 16.9825 24.7042 17.1504 24.7042H22.0288C22.1967 24.7042 22.3328 24.5681 22.3328 24.4002V19.5218C22.3328 19.3539 22.1967 19.2178 22.0288 19.2178H17.1504C16.9825 19.2178 16.8464 19.3539 16.8464 19.5218Z'
          fill='white'
        />
        <path
          d='M16.8464 12.6639V17.5423C16.8464 17.7102 16.9825 17.8463 17.1504 17.8463H22.0288C22.1967 17.8463 22.3328 17.7102 22.3328 17.5423V12.6639C22.3328 12.496 22.1967 12.3599 22.0288 12.3599H17.1504C16.9825 12.3599 16.8464 12.496 16.8464 12.6639Z'
          fill='white'
        />
        <path
          d='M15.1712 5.504H10.2928C10.1249 5.504 9.98877 5.64011 9.98877 5.808V10.6864C9.98877 10.8543 10.1249 10.9904 10.2928 10.9904H15.1712C15.3391 10.9904 15.4752 10.8543 15.4752 10.6864V5.808C15.4752 5.64011 15.3391 5.504 15.1712 5.504Z'
          fill='white'
        />
        <path
          d='M42 8.5H49.5747C52.3161 8.5 53.7188 10.3217 53.7188 12.3232C53.7188 14.2118 52.5972 15.4713 51.2372 15.7643C52.7699 16.0111 54 17.5637 54 19.4522C54 21.7006 52.5544 23.5 49.8131 23.5H42.0015V8.5H42ZM48.9054 14.5255C49.9414 14.5255 50.5893 13.828 50.5893 12.8615C50.5893 11.8949 49.9414 11.2197 48.9054 11.2197H45.0638V14.5255H48.9054ZM49.0139 20.7787C50.1798 20.7787 50.8705 20.0812 50.8705 19.0016C50.8705 18.0573 50.2226 17.2468 49.0139 17.2468H45.0653V20.7771H49.0139V20.7787Z'
          fill='white'
        />
        <path
          d='M74 8.5H81.5747C84.3161 8.5 85.7188 10.3217 85.7188 12.3232C85.7188 14.2118 84.5972 15.4713 83.2372 15.7643C84.7699 16.0111 86 17.5637 86 19.4522C86 21.7006 84.5544 23.5 81.8131 23.5H74.0015V8.5H74ZM80.9054 14.5255C81.9414 14.5255 82.5893 13.828 82.5893 12.8615C82.5893 11.8949 81.9414 11.2197 80.9054 11.2197H77.0638V14.5255H80.9054ZM81.0139 20.7787C82.1798 20.7787 82.8705 20.0812 82.8705 19.0016C82.8705 18.0573 82.2226 17.2468 81.0139 17.2468H77.0653V20.7771H81.0139V20.7787Z'
          fill='white'
        />
        <path
          d='M63.9886 8.5C68.6262 8.5 72 11.6301 72 16C72 20.3699 68.6246 23.5 63.9886 23.5C59.3527 23.5 56 20.3699 56 16C56 11.6301 59.3754 8.5 63.9886 8.5ZM63.9886 11.2385C61.1655 11.2385 59.3511 13.2816 59.3511 15.9985C59.3511 18.7154 61.1655 20.7584 63.9886 20.7584C66.8118 20.7584 68.6489 18.6938 68.6489 15.9985C68.6489 13.3031 66.8118 11.2385 63.9886 11.2385Z'
          fill='white'
        />
        <path
          d='M91 23.2727L93.4148 8.72726H99.1534C100.252 8.72726 101.154 8.93797 101.859 9.35937C102.57 9.77603 103.067 10.3561 103.351 11.0994C103.64 11.8381 103.706 12.6903 103.55 13.6562C103.389 14.6269 103.038 15.4815 102.499 16.2202C101.964 16.9541 101.268 17.527 100.411 17.9389C99.5535 18.3508 98.5639 18.5568 97.4418 18.5568H93.7841L94.1889 16.0923H97.3494C97.946 16.0923 98.455 15.9905 98.8764 15.7869C99.3026 15.5786 99.6411 15.2921 99.892 14.9275C100.143 14.5582 100.306 14.1345 100.382 13.6562C100.463 13.1686 100.437 12.7448 100.304 12.3849C100.171 12.0251 99.9276 11.7457 99.5724 11.5469C99.2221 11.3433 98.7509 11.2415 98.1591 11.2415H96.0852L94.0753 23.2727H91Z'
          fill='#FF6301'
        />
        <path
          d='M104.777 23.2727H101.481L108.917 8.72726H112.88L115.48 23.2727H112.184L110.416 12.0511H110.302L104.777 23.2727ZM105.529 17.5554H113.314L112.902 19.956H105.118L105.529 17.5554Z'
          fill='#FF6301'
        />
        <path
          d='M117.309 8.72726H120.753L123.033 14.9915H123.189L127.529 8.72726H130.973L124.113 18.1307L123.253 23.2727H120.199L121.059 18.1307L117.309 8.72726Z'
          fill='#FF6301'
        />
      </svg>
    </StyledLogo>
  </Flex>
);

export { Logo };
